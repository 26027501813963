<i18n locale="th" lang="yaml" >
page.title : "{name} :: ข้อมูลบริษัท"
tab.info.label : "สรุปข้อมูล"
tab.tickets.label : "Ticket"
tab.users.label : "พนักงาน"
tab.user.permission_management: "จัดการการเข้าถึงข้อมูล"
</i18n>

<template>
	<div class="page page-padding">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<CompanyCard
					style="margin-bottom:32px;"
					:loading="pageLoading"
					:company="company"
					:show-update="!$route.meta.hideAction" />
			</div>
			<div class="mylayout-right">
				<template v-if="!$route.meta.hideTab">
					<a-menu
						mode="horizontal"
						type="inner"
						class="mytab-menu"
						:selected-keys="selectedMenu">
						<a-menu-item key="company/base">
							<my-router-link name="company/base" :param-value="$route.params.id">
								<a-icon type="pie-chart" />
								{{$t('tab.info.label')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item v-if="canViewAllTickets" key="company/tickets">
							<my-router-link name="company/tickets" :param-value="$route.params.id">
								<a-icon type="tool" />
								{{$t('tab.tickets.label')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="company/users">
							<my-router-link name="company/users" :param-value="$route.params.id">
								<a-icon type="user" />
								{{$t('tab.users.label')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item v-if="showManagementTab" key="company/access_management">
							<my-router-link name="company/access_management" :param-value="$route.params.id">
								<a-icon type="user" />
								{{$t('tab.user.permission_management')}}
							</my-router-link>
						</a-menu-item>

					</a-menu>
					<div class="mytab-content">
						<keep-alive>
							<router-view :company="company"></router-view>
						</keep-alive>
					</div>
				</template>
				<template v-else>
					<router-view :company="company"></router-view>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import CompanyCard from "@components/company/CompanyCard.vue"
import axios from "axios"
import {Menu} from "ant-design-vue"

export default {
	components : {
		CompanyCard,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	mixins : [PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title: this.$t('page.title',{name:this.company.name}),
		}
	},
	data() {
		return {
			company: {} ,
			selectedMenu : []
		}
	} ,
	computed : {
		canViewAllTickets() {
			return this.$authorize('listAll','ticket')
		},
		showManagementTab() {
			const companyType = this.currentCompany.company_type
			return  this.$isManagement(companyType) && (this.company.company_type === 3 || this.company.company_type === 4)
		}
	} ,
	watch: {
		$route : {
			handler(newVal){
				if (!this.company || newVal.params.id != this.company.id) {
					this.fetchData()
				}
				this.updateSelectedMenu()
			}
		}
	} ,
	mounted() {
		this.updateSelectedMenu()
		this.fetchData()
	} ,
	methods : {
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		fetchData() {
			const companyId = this.$route.params.id;
			if (!companyId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams(['companyName'])
			axios.get("/api/companies/"+companyId).then((response) => {
				this.company = response.data.data.company;

				this.addBreadcrumbParams({
					'companyName' : this.company.name ,
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=> {
				this.hidePageLoading()
			})
		}
	}
}
</script>
